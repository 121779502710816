.order-item-photos-wrapper {
    width: 210px;
}

.order-item-photos-wrapper--auto-width {
    width: 90px !important;
    position: relative;
    top: -19px;
}

.order-item-photos-wrapper--auto-width .order-item-photos:hover {
    right: 35px !important;
}

.order-item-photos {
    position: relative;
    width: 50px;
    margin-right: 10px;
}

.order-item-photos__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 45px;
    height: 45px;
    background-size: cover;
    background-position: center center;
    border: 1px solid white;
    box-shadow: 0 1px 3px -2px rgba(0,0,0,.5);
}

.order-item-photos__item--pdf, .order-item-photos__item--pdf:hover {
    background: red;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-item-photos__item--upload {
    z-index: 9999;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    transform: rotate3d(0,0,0,0) !important;
}

.order-item-photos:hover .order-item-photos__item--upload {
    border: 1px solid #000;
    background: #fff;
    color: #000;
}

.order-item-photos__item:nth-child(1) {
    z-index: 999;
}

.order-item-photos__item:nth-child(2) {
    transform: rotate3d(0,0,1,12deg);
}

.order-item-photos__item:nth-child(3) {
    transform: rotate3d(0,0,1,-12deg);
}

.order-item-photos__item:nth-child(4) {
    transform: rotate3d(0,0,1,8deg);
}

.order-item-photos__item:nth-child(5) {
    transform: rotate3d(0,0,1,-8deg);
}

.order-item-photos:hover {
    width: 1000px;
    position: absolute;
    right: 53px;
    display: flex;
    justify-content: flex-end;
}

.order-item-photos:hover .order-item-photos__item {
    position: relative;
}

.order-item-photos:hover .order-item-photos__item {

}

.order-item-photos:hover .order-item-photos__item:nth-child(2),
.order-item-photos:hover .order-item-photos__item:nth-child(3),
.order-item-photos:hover .order-item-photos__item:nth-child(4),
.order-item-photos:hover .order-item-photos__item:nth-child(5) {
    transform: rotate3d(0,0,0,0);
}